import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

/*
 * Following is a translation loader for consumer app,
 * enable it if you wish to pass a custom configuration as described below.
 */

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

/*
 * Following is the config for i18n init.
 * locale, fallback locale and message can be configured here.
 * After configuration, needs to be passed to VuI18n() down below.
 */

const config = {
  // locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
};

export default new VueI18n(config);
