import Vue from 'vue';
import VueRouter from 'vue-router';

import Start from '../views/Start.vue';
import Consumer from '../views/Consumer.vue';

Vue.use(VueRouter);

// Additional routes of the consumer app
const routes = [
  {
    path: '/',
    name: 'start',
    component: Start,
  },
  {
    path: '/consumer',
    name: 'consumer',
    component: Consumer,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
