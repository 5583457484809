import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueLogger from 'vuejs-logger';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import enquete from '@RREADY-ORG/enquete-frontend';
import enqueteConfig from '@/enquete.config';
import '@RREADY-ORG/enquete-frontend/dist/enquete.css';
import './assets/overrides.css';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.use(enquete, {
  VueLogger,
  store,
  router,
  i18n,
  VueSlider,
  config: enqueteConfig,
});

Vue.use(VueI18n);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
