import Vue from 'vue';
// import i18n from '@/i18n';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

// strict: process.env.NODE_ENV !== 'production',
const state = () => ({
  hello: true,
  appState: {},
});

const mutations = {
  setAppState(state, [prop, value]) {
    Vue.set(state.appState, prop, value);
  },
};

const actions = {
};

const getters = {
  saysHello: (state) => !!state.hello
  ,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
