import logoLight from './assets/images/lean_maturity_check_logo_white.svg';
import logoDark from './assets/images/logo-simplicity-dark.svg';
import outroHero from './assets/outro-hero.png';

const config = {
  headless: false,
  theme: {
    svg: {
      logoLight,
      logoDark,
    },
  },
  frontend: {
    hasLogger: process.env.VUE_APP_ENV === 'development' || process.env.VUE_APP_ENV === 'staging',
    smartlookToken: process.env.VUE_APP_ASSESSMENTS_SMARTLOOK_TOKEN,
    baseUrl: process.env.VUE_APP_BASE_URL,
  },
  backend: {
    baseUrl: process.env.VUE_APP_API_URL,
  },
  tools: {
    quiz: {
      intro: {
        hasQuestion: true,
        answers: {
          a: {
            localeKey: 'employee',
            value: 'employee',
          },
          b: {
            localeKey: 'leanAmbassador',
            value: 'leanAmbassador',
          },
          c: {
            localeKey: 'leanMaster',
            value: 'leanMaster',
          },
          d: {
            localeKey: 'leanSponsor',
            value: 'leanSponsor',
          },
        },
      },
      outro: {
        heroImage: outroHero,
        callToActionLink: 'https://swisscom.sharepoint.com/sites/simplicity/SitePages/Simplicity.aspx',
      },
    },
  },
};

export default config;
