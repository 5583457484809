import Vue from 'vue';
import Vuex from 'vuex';

// import the auto exporter
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
});
