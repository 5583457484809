<template>
  <div class="home">
    <code class="text-xs text-gray-600">
      {{ env.VUE_APP_ENV === 'staging' || env.VUE_APP_ENV === 'development'
        ? `${env.VUE_APP_ENV}-${env.VERSION}`
        : null }}
    </code>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    ...mapState({
      appState: (state) => state.enquete.common.appState,
    }),
  },
  methods: {
  },
  mounted() {
  },
};
</script>
