<template>
  <div id="app" class="p-5">
    <transition name="view-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  mounted() {
    // this.$log.info('utils', this.$utils.getThemeLogo());
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
